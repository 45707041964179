import { policyList } from "@/enums/permissions";
import { store } from "@/internal";
import { i18n } from "@/plugins/i18n";
import { FormField, FormFieldTypes } from "helix-vue-components";
import { RetailSettings } from "../interfaces/retailSettings";

export const priceOptionFormMetadata: FormField[] = [
  {
    component: FormFieldTypes.switch,
    label: i18n.t("inventory.cumulative_pricing"),
    name: "cumulative_pricing",
    options: {
      disableCondition: () =>
        !store.getters["PermissionsModule/hasPermission"](
          policyList.modifyPricingOptions
        ),
      row: 1,
      flex: 4
    }
  },
  {
    component: FormFieldTypes.switch,
    label: i18n.t("inventory.price_point_grouping"),
    name: "price_point_grouping",
    options: {
      disableCondition: () =>
        !store.getters["PermissionsModule/hasPermission"](
          policyList.modifyPricingOptions
        ),
      row: 2,
      flex: 4
    }
  },
  {
    component: FormFieldTypes.radioGroup,
    label: i18n.t("inventory.retail_pricing"),
    name: "retail_pre_tax_pricing",
    options: {
      validationRules: ["required"],
      vuetifyProps: {
        row: true,
        disabled: !store.getters["PermissionsModule/hasPermission"](
          policyList.modifyPricingOptions
        )
      },
      groupOptions: [
        {
          text: i18n.t("inventory.pre_tax_pricing"),
          value: 1
        },
        {
          text: i18n.t("inventory.post_tax_pricing"),
          value: 0
        }
      ]
    }
  },
  {
    component: FormFieldTypes.radioGroup,
    label: i18n.t("inventory.wholesale_pricing"),
    name: "wholesale_pre_tax_pricing",
    options: {
      validationRules: ["required"],
      vuetifyProps: {
        row: true,
        disabled: !store.getters["PermissionsModule/hasPermission"](
          policyList.modifyPricingOptions
        )
      },
      groupOptions: [
        {
          text: i18n.t("inventory.pre_tax_pricing"),
          value: 1
        },
        {
          text: i18n.t("inventory.post_tax_pricing"),
          value: 0
        }
      ]
    }
  }
];
