import { policyList } from "@/enums/permissions";
import { RetailSettings } from "@/interfaces/retailSettings";
import { priceOptionFormMetadata } from "@/metadata/priceOption";
import { Callback, PageNavAction } from "@/types/types";
import { BooleanCheck, DynamicFormComponent } from "helix-vue-components";
import pick from "lodash/pick";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./PricingOption.template.vue";

const namespace = "AuthModule";

@Component({
  mixins: [Template],
  components: {
    DynamicFormComponent
  }
})
export default class PricingOptionComponent extends Vue {
  @Action("saveCurrentLocationPricing", { namespace })
  public saveAction!: Callback;
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;

  public isSaving = false;
  public canSave = false;
  public model: Partial<RetailSettings> = {
    cumulative_pricing: 0,
    price_point_grouping: 0,
    retail_pre_tax_pricing: 0,
    wholesale_pre_tax_pricing: 0
  };
  protected form = priceOptionFormMetadata;

  @Getter("currentRetailSettings", { namespace })
  protected currentRetailSettings!: RetailSettings;

  @Getter("hasPermission", { namespace: "PermissionsModule" })
  protected hasPermission!: BooleanCheck;

  public async save() {
    const form = await (this.$refs.priceForm as DynamicFormComponent).submit();

    if (form.valid) {
      this.isSaving = true;
      const data = {
        ...this.currentRetailSettings,
        ...this.cleanValues(form.currentModel as Partial<RetailSettings>)
      };
      await this.saveAction(data);
      this.isSaving = false;
    }
  }

  public cancel() {
    this.$router.push({
      name: "pricing-tools"
    });
  }

  protected mounted() {
    this.canSave = this.hasPermission(policyList.modifyPricingOptions);
    if (this.currentRetailSettings) {
      this.model = pick(this.currentRetailSettings, Object.keys(this.model));
    }
    this.setPageNav({
      title: "pricing_options",
      isLoading: () => this.isSaving,
      rightActions: {
        generalActions: () => [
          {
            icon: "fal fa-check",
            action: this.save,
            vuetifyProps: () => ({
              loading: this.isSaving,
              fab: true,
              small: true
            })
          },
          {
            icon: "fal fa-times",
            action: this.cancel,
            vuetifyProps: () => ({
              loading: this.isSaving,
              fab: true,
              small: true
            })
          }
        ]
      }
    });
  }

  private cleanValues(data: Partial<RetailSettings>): Partial<RetailSettings> {
    const obj: Partial<RetailSettings> = {};
    Object.entries(data).forEach(d => {
      if (d[1] === null) {
        d[1] = 0;
      }

      obj[d[0]] = d[1];
    });

    return obj;
  }
}
